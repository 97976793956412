import { Container } from "@mui/material";
import React from "react";
import FormImgCompress from "../../component/form/FormImgCompress";
import FileTest from "./FileTest";

export default function TestPage() {
  return (
    <Container>
      {/* <FormImgCompress /> */}
      <FileTest />
    </Container>
  );
}
